import React from 'react'

import { Context as AuthContext } from 'context/AuthContext'

export const Login = (props) => {
	const auth = React.useContext(AuthContext)

	const [username, setUsername] = React.useState('')
	const [password, setPassword] = React.useState('')
	const [error, setError] = React.useState('')

	const handleSubmit = async (e) => {
		e.preventDefault()
		setError('')

		if (!username || !password) return

		try {
			await auth.login(username, password)
		} catch (e) {
			if (e?.response?.status === 401) {
				setError('Credenziali non valide')
			} else {
				setError('Si è verificato un errore')
			}
		}
	}
	return (
		<div className="login">
			<div className="nav">Area Soci</div>
			<div className="body">
				<form onSubmit={handleSubmit}>
					<div>Username</div>
					<input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />

					<br />

					<div>Password</div>
					<input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />

					<br />

					<button type="submit" className="blue" disabled={!username || !password}>
						Accedi
					</button>
				</form>

				{error && <p className="error">{error}</p>}
			</div>
		</div>
	)
}

export default Login
