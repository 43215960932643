import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { RouteWithLayout } from './components'

import * as layouts from 'layouts'

import { Login } from 'views/Login/Login.js'

const PublicRoutes = (props) => {
	return (
		<Switch>
			<RouteWithLayout component={Login} exact layout={layouts.Main} path="/" />

			<Redirect
				to={{
					pathname: '/',
					state: { redirectTo: props.redirectTo },
				}}
			/>
		</Switch>
	)
}

export default PublicRoutes
