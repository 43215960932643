import React from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import config from '../config'
import { Editor } from '@tinymce/tinymce-react'

import removePic from '../assets/images/missing.png'
import imgPlaceholder from '../assets/images/missing2.png'

export const Block = (props) => {
	const auth = React.useContext(AuthContext)
	const history = useHistory()

	const [name, setName] = React.useState('')
	const [block, setBlock] = React.useState({})
	const [page, setPage] = React.useState({})
	const [post, setPost] = React.useState({})
	const editorRef = React.useRef()
	const editorRefEn = React.useRef()

	const loadData = async (reset) => {
		if (reset) setBlock({})

		try {
			const block_id = props.match.params.block_id

			const { data: block } = await axios({
				url: `${config.apiURL}/api/v1/blocks/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: block_id,
				},
			})

			const { data: post } = await axios({
				url: `${config.apiURL}/api/v1/posts/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: block.post_id,
				},
			})

			const { data: page } = await axios({
				url: `${config.apiURL}/api/v1/pages/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: post.page_id,
				},
			})

			setPage(page)
			setPost(post)
			setBlock(block)
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	React.useEffect(() => {
		if (!props.match.params.block_id) {
			props.history.goBack()
			return
		} else {
			loadData()
		}
	}, [])

	const handleSave = async (e) => {
		const content = editorRef.current.getContent()
		const content_en = editorRefEn.current.getContent()

		const { data } = await axios({
			url: `${config.apiURL}/api/v1/p/blocks/upsert`,
			method: 'POST',
			data: {
				version: config.version,
				client: config.client,
				accessToken: auth.accessToken,
				...block,
				content,
				content_en,
			},
		})

		props.history.goBack()
	}

	return (
		<div className="screen blocks">
			<div className="nav">
				<a className="back" href={`/blocks/${block.post_id}`}>
					&larr;
				</a>
				Pagina: {page.name}, Post: {post.name}, Blocco: {block.name}
			</div>
			<div className="body">
				<p>Italiano</p>
				<Editor
					apiKey={config.tinyMCE.apiKey}
					onInit={(evt, editor) => (editorRef.current = editor)}
					initialValue={block.content}
					init={{
						height: 500,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount image',
						],
						toolbar:
							'undo redo | formatselect | ' +
							'bold italic backcolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | ' +
							'removeformat | help | code link image',
						content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
					}}
				/>
				<button className="gray small sans" onClick={handleSave}>
					Salva
				</button>

				<br />
				<br />
				<br />
				<br />
				<p>Inglese</p>
				<Editor
					apiKey={config.tinyMCE.apiKey}
					onInit={(evt, editor) => (editorRefEn.current = editor)}
					initialValue={block.content_en}
					init={{
						height: 500,
						menubar: false,
						plugins: [
							'advlist autolink lists link image charmap print preview anchor',
							'searchreplace visualblocks code fullscreen',
							'insertdatetime media table paste code help wordcount image',
						],
						toolbar:
							'undo redo | formatselect | ' +
							'bold italic backcolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | ' +
							'removeformat | help | code link image',
						content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
					}}
				/>
				<button className="gray small sans" onClick={handleSave}>
					Salva
				</button>
			</div>
		</div>
	)
}

export default Block
