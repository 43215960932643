import React from 'react'

import * as auth from './AuthContext'

export const Consumers = {
	auth: auth.Consumer,
}

export const Contexts = {
	auth: auth.Context,
}

export const Providers = {
	auth: auth.Provider,
}

export const ContextProvider = (props) => {
	return <auth.Provider>{props.children}</auth.Provider>
}
