import React from 'react'

import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { ThemeProvider } from '@material-ui/styles'
import ReactGA from 'react-ga'
import { loadProgressBar } from 'axios-progress-bar'

import theme from './theme'
import './assets/scss/index.scss'

import config from 'config'
import { Routes, PublicRoutes, AdminRoutes } from 'routes'

import { Context as AuthContext } from 'context/AuthContext'

const browserHistory = createBrowserHistory()

export default (props) => {
	const auth = React.useContext(AuthContext)

	React.useEffect(() => {
		loadProgressBar()

		const track = !config.debug && config?.google?.analytics_monitoring_id
		if (track) {
			ReactGA.initialize(config.google.analytics_monitoring_id)
		}

		browserHistory.listen((location) => {
			if (track) {
				ReactGA.set({ page: location.pathname }) // Update the user's current page
				ReactGA.pageview(location.pathname) // Record a pageview for the given page
			}
		})
	}, [])

	React.useEffect(() => {
		if (!config.debug && auth.currentUser) {
			ReactGA.set({ user: auth.currentUser.id })
		}
	}, [auth.currentUser])

	return (
		<ThemeProvider theme={theme}>
			<Router history={browserHistory}>
				{auth.accessToken && auth.currentUser.role === 'user' && <Routes />}
				{auth.accessToken && auth.currentUser.role === 'admin' && <AdminRoutes />}
				{!auth.accessToken && <PublicRoutes redirectTo={browserHistory.location.pathname} />}
			</Router>
		</ThemeProvider>
	)
}
