import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { RouteWithLayout } from './components'
import { Main as MainLayout } from 'layouts'

import { Dashboard } from 'views/Dashboard/Dashboard.js'
import { Files } from 'views/Files.js'
import { Pages } from 'views/Pages.js'
import { Posts } from 'views/Posts.js'
import { Blocks } from 'views/Blocks.js'
import { Block } from 'views/Block.js'
import { NotFound } from 'views/NotFound/NotFound.js'

const Routes = (props) => {
	return (
		<Switch>
			<RouteWithLayout component={Dashboard} exact layout={MainLayout} path="/dashboard" />
			<RouteWithLayout component={Files} exact layout={MainLayout} path="/files" />
			<RouteWithLayout component={Pages} exact layout={MainLayout} path="/pages" />
			<RouteWithLayout component={Posts} exact layout={MainLayout} path="/posts/:page_id" />
			<RouteWithLayout component={Blocks} exact layout={MainLayout} path="/blocks/:post_id" />
			<RouteWithLayout component={Block} exact layout={MainLayout} path="/block/:block_id" />

			<RouteWithLayout component={NotFound} exact layout={MainLayout} path="/not-found" />

			<Redirect exact from="/" to="/dashboard" />

			<Redirect exact from="/login" to="/" />

			<Redirect to="/not-found" />
		</Switch>
	)
}

export default Routes
