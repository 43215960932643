import React from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import config from '../config'

import removePic from '../assets/images/missing.png'
import imgPlaceholder from '../assets/images/missing.png'

export const Files = (props) => {
	const auth = React.useContext(AuthContext)
	const history = useHistory()

	const [name, setName] = React.useState('')
	const [files, setFiles] = React.useState([])
	const inputRef = React.useRef()
	const [editFile, setEditFile] = React.useState(null)

	const loadData = async (reset) => {
		if (reset) setFiles([])
		try {
			const { data } = await axios({
				url: `${config.apiURL}/api/v1/p/files/all`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
				},
			})

			setFiles(data)
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	React.useEffect(() => {
		loadData()
	}, [])

	React.useEffect(() => {
		if (!editFile) return
		inputRef.current && inputRef.current.click()
	}, [editFile])

	return (
		<div className="screen files">
			<div className="nav">
				<a className="back" href="/dashboard">
					&larr;
				</a>
				Gestione file
			</div>
			<div className="body">
				<input
					accept="*"
					style={{ display: 'none' }}
					type="file"
					onChange={async (event, file) => {
						const formData = new FormData()
						formData.append('accessToken', auth.accessToken)
						formData.append('version', config.version)
						formData.append('client', config.client)
						formData.append('set_picture', true)

						try {
							formData.append('file', event.target.files[0])

							await axios({
								url: `${config.apiURL}/api/v1/p/files/upsert`,
								method: 'POST',
								data: formData,
							})

							loadData(true)
						} catch (error) {
							if (config.debug) console.log(error)
						}

						setEditFile(null)
						inputRef.current.value = ''
					}}
					ref={inputRef}
				/>
				<div className="file">
					<div className="buttons" style={{ width: '100%', textAlign: 'center' }}>
						<button
							className="gray small sans"
							onClick={async (event) => {
								setEditFile(true)
							}}
						>
							Aggiungi file
						</button>
					</div>
				</div>
				{files.map((file, i) => (
					<div className="file" key={i}>
						<div className="name">
							<a target="_blank" href={file.url}>
								{file.name}
							</a>
						</div>

						<input type="text" value={file.url} />

						<div className="buttons">
							<button
								className="gray small sans"
								onClick={async (event) => {
									navigator.clipboard.writeText(file.url)
								}}
							>
								copia url
							</button>
							<button
								className="gray small sans"
								onClick={async (event) => {
									if (!window.confirm("Confermare l'operazione?")) return

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/files/delete`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...file,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								x
							</button>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Files
