import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { RouteWithLayout } from './components'
import { Main as MainLayout } from 'layouts'

import { Dashboard } from 'views/Dashboard/Dashboard.js'
import { NotFound } from 'views/NotFound/NotFound.js'

const Routes = (props) => {
	return (
		<Switch>
			<RouteWithLayout component={Dashboard} exact layout={MainLayout} path="/dashboard" />

			<RouteWithLayout component={NotFound} exact layout={MainLayout} path="/not-found" />

			<Redirect exact from="/" to="/dashboard" />

			<Redirect exact from="/login" to="/" />

			<Redirect to="/not-found" />
		</Switch>
	)
}

export default Routes
