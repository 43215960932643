import React from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import config from '../../config'

import imgPlaceholder from '../../assets/images/missing.png'

export const Dashboard = (props) => {
	const auth = React.useContext(AuthContext)
	const history = useHistory()

	return (
		<div className="dashboard">
			<div className="nav">Benvenuto {auth.currentUser.username.replaceAll('_', ' ')}</div>
			<div className="body">
				{auth.currentUser.role === 'admin' && (
					<>
						<div className="buttons">
							<a href="/pages">
								<button className="gray">Gestione Pagine</button>
							</a>
							<a href="/files">
								<button className="gray">Gestione File</button>
							</a>
						</div>
						<br />
					</>
				)}
				<div className="buttons">
					<button className="gray" onClick={auth.logout}>
						Esci
					</button>
				</div>
			</div>
		</div>
	)
}

export default Dashboard
