import React from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import config from '../config'

import removePic from '../assets/images/missing.png'
import imgPlaceholder from '../assets/images/missing2.png'

export const Blocks = (props) => {
	const auth = React.useContext(AuthContext)
	const history = useHistory()

	const [name, setName] = React.useState('')
	const [page, setPage] = React.useState({})
	const [post, setPost] = React.useState({})
	const [blocks, setBlocks] = React.useState([])
	const inputRef = React.useRef()
	const [editPicture, setEditPicture] = React.useState(null)

	const loadData = async (reset) => {
		if (reset) setBlocks([])

		try {
			const post_id = props.match.params.post_id

			const { data: post } = await axios({
				url: `${config.apiURL}/api/v1/posts/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: post_id,
				},
			})

			const { data: page } = await axios({
				url: `${config.apiURL}/api/v1/pages/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: post.page_id,
				},
			})

			const { data: blocks } = await axios({
				url: `${config.apiURL}/api/v1/blocks/all`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					post_id: post.id,
				},
			})

			setPage(page)
			setPost(post)
			setBlocks(blocks)
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	React.useEffect(() => {
		if (!props.match.params.post_id) {
			props.history.goBack()
			return
		} else {
			loadData()
		}
	}, [])

	React.useEffect(() => {
		if (!editPicture) return
		inputRef.current && inputRef.current.click()
	}, [editPicture])

	return (
		<div className="screen blocks">
			<div className="nav">
				<a className="back" href={`/posts/${post.page_id}`}>
					&larr;
				</a>
				Pagina: {page.name}, Post: {post.name}
			</div>
			<div className="body">
				<input
					accept="image/*"
					style={{ display: 'none' }}
					type="file"
					onChange={async (event, file) => {
						const block = editPicture

						const formData = new FormData()
						formData.append('accessToken', auth.accessToken)
						formData.append('version', config.version)
						formData.append('client', config.client)
						formData.append('set_picture', true)
						formData.append('id', block.id)
						formData.append('ord', block.ord)

						try {
							formData.append('picture', event.target.files[0])

							await axios({
								url: `${config.apiURL}/api/v1/p/blocks/upsert`,
								method: 'POST',
								data: formData,
							})

							loadData(true)
						} catch (error) {
							if (config.debug) console.log(error)
						}

						inputRef.current.value = ''
					}}
					ref={inputRef}
				/>
				{blocks.map((block, i) => (
					<div className="block" key={i}>
						<div className="ord">{block.ord}</div>
						<div className="icon">
							<img
								src={block.picture + '?' + new Date().getTime()}
								onError={(e) => {
									e.target.onError = null
									e.target.src = imgPlaceholder
								}}
								onClick={(e) => {
									setEditPicture(block)
								}}
							/>
						</div>
						<div className="name">
							<input
								type="text"
								placeholder="nome (it)"
								value={block.name}
								onChange={async (event) => {
									block = {
										...block,
										name: event.target.value,
									}

									setBlocks(blocks.map((_block, _i) => (_i === i ? block : _block)))

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/blocks/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...block,
											},
										})
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							/>
							<br />
							<input
								type="text"
								placeholder="nome (en)"
								value={block.name_en}
								onChange={async (event) => {
									block = {
										...block,
										name_en: event.target.value,
									}

									setBlocks(blocks.map((_block, _i) => (_i === i ? block : _block)))

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/blocks/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...block,
											},
										})
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							/>
						</div>
						<div className="buttons">
							<button
								className="gray small sans"
								onClick={async (event) => {
									if (!window.confirm("Confermare l'operazione?")) return

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/blocks/delete`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...block,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								x
							</button>
							<button
								className="gray small sans"
								onClick={async (event) => {
									if (!window.confirm("Confermare l'operazione?")) return

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/blocks/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...block,
												set_picture: true,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								<img src={removePic} />
							</button>
							<button
								className="gray small sans"
								onClick={async (event) => {
									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/blocks/up`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...block,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								&uarr;
							</button>
							<button
								className="gray small sans"
								onClick={async (event) => {
									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/blocks/down`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...block,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								&darr;
							</button>
							{!block.readonly && (
								<a href={`/block/${block.id}`}>
									<button className="gray small sans">&rarr;</button>
								</a>
							)}
						</div>
						<div className="checks">
							<label htmlFor={`block_enabled${i}`}>visibile</label>
							<input
								type="checkbox"
								name={`block_enabled${i}`}
								id={`block_enabled${i}`}
								checked={block.enabled}
								onChange={async (event) => {
									block = {
										...block,
										enabled: event.target.checked,
									}

									setBlocks(blocks.map((_block, _i) => (_i === i ? block : _block)))

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/blocks/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...block,
											},
										})
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							/>
							<br />
							{/* <div>tipo: {block.type}</div> */}
						</div>
					</div>
				))}

				<div className="block">
					<div className="ord">+</div>
					<div className="name">
						<input
							type="text"
							value={name}
							onChange={(event) => {
								setName(event.target.value)
							}}
						/>
					</div>
					<div className="buttons">
						<button
							className="gray small sans"
							onClick={async (event) => {
								try {
									const { data } = await axios({
										url: `${config.apiURL}/api/v1/p/blocks/upsert`,
										method: 'POST',
										data: {
											version: config.version,
											client: config.client,
											accessToken: auth.accessToken,
											name,
											post_id: post.id,
											ord: blocks.length + 1,
										},
									})

									loadData()
									setName('')
								} catch (error) {
									if (config.debug) console.log(error)
								}
							}}
						>
							Aggiungi blocco
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Blocks
