import React from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import config from '../config'

import removePic from '../assets/images/missing.png'
import imgPlaceholder from '../assets/images/missing.png'

export const Pages = (props) => {
	const auth = React.useContext(AuthContext)
	const history = useHistory()

	const [name, setName] = React.useState('')
	const [pages, setPages] = React.useState([])
	const inputRef = React.useRef()
	const [editPicture, setEditPicture] = React.useState(null)

	const loadData = async (reset) => {
		if (reset) setPages([])
		try {
			const { data } = await axios({
				url: `${config.apiURL}/api/v1/pages/all`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
				},
			})

			setPages(data)
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	React.useEffect(() => {
		loadData()
	}, [])

	React.useEffect(() => {
		if (!editPicture) return
		inputRef.current && inputRef.current.click()
	}, [editPicture])

	return (
		<div className="screen pages">
			<div className="nav">
				<a className="back" href="/dashboard">
					&larr;
				</a>
				Gestione pagine
			</div>
			<div className="body">
				<input
					accept="image/*"
					style={{ display: 'none' }}
					type="file"
					onChange={async (event, file) => {
						const page = editPicture

						const formData = new FormData()
						formData.append('accessToken', auth.accessToken)
						formData.append('version', config.version)
						formData.append('client', config.client)
						formData.append('set_picture', true)
						formData.append('id', page.id)
						formData.append('ord', page.ord)

						try {
							formData.append('picture', event.target.files[0])

							await axios({
								url: `${config.apiURL}/api/v1/p/pages/upsert`,
								method: 'POST',
								data: formData,
							})

							loadData(true)
						} catch (error) {
							if (config.debug) console.log(error)
						}

						inputRef.current.value = ''
					}}
					ref={inputRef}
				/>
				{pages.map((page, i) => (
					<div className="page" key={i}>
						<div className="ord">{page.ord}</div>
						<div className="icon">
							<img
								src={page.picture + '?' + new Date().getTime()}
								onError={(e) => {
									e.target.onError = null
									e.target.src = imgPlaceholder
								}}
								onClick={(e) => {
									setEditPicture(page)
								}}
							/>
						</div>
						<div className="name">
							<input
								type="text"
								placeholder="nome (it)"
								value={page.name}
								onChange={async (event) => {
									page = {
										...page,
										name: event.target.value,
									}

									setPages(pages.map((_page, _i) => (_i === i ? page : _page)))

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/pages/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...page,
											},
										})
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							/>
							<br />
							<input
								type="text"
								placeholder="nome (en)"
								value={page.name_en}
								onChange={async (event) => {
									page = {
										...page,
										name_en: event.target.value,
									}

									setPages(pages.map((_page, _i) => (_i === i ? page : _page)))

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/pages/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...page,
											},
										})
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							/>
						</div>
						<div className="buttons">
							<button
								className="gray small sans"
								onClick={async (event) => {
									if (!window.confirm("Confermare l'operazione?")) return

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/pages/delete`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...page,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								x
							</button>
							<button
								className="gray small sans"
								onClick={async (event) => {
									if (!window.confirm("Confermare l'operazione?")) return

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/pages/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...page,
												set_picture: true,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								<img src={removePic} />
							</button>
							<button
								className="gray small sans"
								onClick={async (event) => {
									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/pages/up`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...page,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								&uarr;
							</button>
							<button
								className="gray small sans"
								onClick={async (event) => {
									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/pages/down`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...page,
											},
										})

										loadData()
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							>
								&darr;
							</button>
							{!page.readonly && (
								<a href={`posts/${page.id}`}>
									<button className="gray small sans">&rarr;</button>
								</a>
							)}
						</div>
						<div className="checks">
							<label htmlFor={`page_enabled${i}`}>visibile</label>
							<input
								type="checkbox"
								name={`page_enabled${i}`}
								id={`page_enabled${i}`}
								checked={page.enabled}
								onChange={async (event) => {
									page = {
										...page,
										enabled: event.target.checked,
									}

									setPages(pages.map((_page, _i) => (_i === i ? page : _page)))

									try {
										const { data } = await axios({
											url: `${config.apiURL}/api/v1/p/pages/upsert`,
											method: 'POST',
											data: {
												version: config.version,
												client: config.client,
												accessToken: auth.accessToken,
												...page,
											},
										})
									} catch (error) {
										if (config.debug) console.log(error)
									}
								}}
							/>
						</div>
					</div>
				))}

				<div className="page">
					<div className="ord">+</div>
					<div className="name">
						<input
							type="text"
							value={name}
							onChange={(event) => {
								setName(event.target.value)
							}}
						/>
					</div>
					<div className="buttons">
						<button
							className="gray small sans"
							onClick={async (event) => {
								try {
									const { data } = await axios({
										url: `${config.apiURL}/api/v1/p/pages/upsert`,
										method: 'POST',
										data: {
											version: config.version,
											client: config.client,
											accessToken: auth.accessToken,
											name,
											ord: pages.length + 1,
										},
									})

									loadData()
									setName('')
								} catch (error) {
									if (config.debug) console.log(error)
								}
							}}
						>
							Aggiungi pagina
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Pages
