import React from 'react'
import { useHistory } from 'react-router-dom'

import axios from 'axios'
import { Context as AuthContext } from 'context/AuthContext'
import config from '../config'

import removePic from '../assets/images/missing.png'
import imgPlaceholder from '../assets/images/missing2.png'
import { ChromePicker } from 'react-color'

export const Posts = (props) => {
	const auth = React.useContext(AuthContext)
	const history = useHistory()

	const [name, setName] = React.useState('')
	const [page, setPage] = React.useState({})
	const [posts, setPosts] = React.useState([])
	const inputRef = React.useRef()
	const [editPicture, setEditPicture] = React.useState(null)

	const loadData = async (reset) => {
		if (reset) setPosts([])

		try {
			const page_id = props.match.params.page_id

			const { data: page } = await axios({
				url: `${config.apiURL}/api/v1/pages/get`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					id: page_id,
				},
			})

			const { data: posts } = await axios({
				url: `${config.apiURL}/api/v1/posts/all`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					page_id: page.id,
				},
			})

			setPage(page)
			setPosts(posts)
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	React.useEffect(() => {
		if (!props.match.params.page_id) {
			props.history.goBack()
			return
		} else {
			loadData()
		}
	}, [])

	React.useEffect(() => {
		if (!editPicture) return
		inputRef.current && inputRef.current.click()
	}, [editPicture])

	const updatePost = async (post, i) => {
		setPosts(posts.map((_post, _i) => (_i === i ? post : _post)))

		try {
			const { data } = await axios({
				url: `${config.apiURL}/api/v1/p/posts/upsert`,
				method: 'POST',
				data: {
					version: config.version,
					client: config.client,
					accessToken: auth.accessToken,
					...post,
				},
			})
		} catch (error) {
			if (config.debug) console.log(error)
		}
	}

	return (
		<div className="screen posts">
			<div className="nav">
				<a className="back" href={`/pages`}>
					&larr;
				</a>
				Pagina: {page.name}
			</div>
			<div className="body">
				<input
					accept="image/*"
					style={{ display: 'none' }}
					type="file"
					onChange={async (event, file) => {
						const post = editPicture

						const formData = new FormData()
						formData.append('accessToken', auth.accessToken)
						formData.append('version', config.version)
						formData.append('client', config.client)
						formData.append('set_picture', true)
						formData.append('id', post.id)
						formData.append('ord', post.ord)

						try {
							formData.append('picture', event.target.files[0])

							await axios({
								url: `${config.apiURL}/api/v1/p/posts/upsert`,
								method: 'POST',
								data: formData,
							})

							loadData(true)
						} catch (error) {
							if (config.debug) console.log(error)
						}

						inputRef.current.value = ''
					}}
					ref={inputRef}
				/>
				{posts.map((post, i) => (
					<div className="post" key={i}>
						<div className="row">
							<div className="ord">{post.ord}</div>
							<div className="icon">
								<img
									src={post.picture + '?' + new Date().getTime()}
									onError={(e) => {
										e.target.onError = null
										e.target.src = imgPlaceholder
									}}
									onClick={(e) => {
										setEditPicture(post)
									}}
								/>
							</div>
							<div className="name">
								<input
									type="text"
									placeholder="nome (it)"
									value={post.name}
									onChange={async (event) => {
										updatePost(
											{
												...post,
												name: event.target.value,
											},
											i
										)
									}}
								/>
								<br />
								<input
									type="text"
									placeholder="nome (en)"
									value={post.name_en}
									onChange={async (event) => {
										updatePost(
											{
												...post,
												name_en: event.target.value,
											},
											i
										)
									}}
								/>
							</div>
							<div className="buttons">
								<button
									className="gray small sans"
									onClick={async (event) => {
										if (!window.confirm("Confermare l'operazione?")) return

										try {
											const { data } = await axios({
												url: `${config.apiURL}/api/v1/p/posts/delete`,
												method: 'POST',
												data: {
													version: config.version,
													client: config.client,
													accessToken: auth.accessToken,
													...post,
												},
											})

											loadData()
										} catch (error) {
											if (config.debug) console.log(error)
										}
									}}
								>
									x
								</button>
								<button
									className="gray small sans"
									onClick={async (event) => {
										if (!window.confirm("Confermare l'operazione?")) return

										try {
											const { data } = await axios({
												url: `${config.apiURL}/api/v1/p/posts/upsert`,
												method: 'POST',
												data: {
													version: config.version,
													client: config.client,
													accessToken: auth.accessToken,
													...post,
													set_picture: true,
												},
											})

											loadData()
										} catch (error) {
											if (config.debug) console.log(error)
										}
									}}
								>
									<img src={removePic} />
								</button>
								<button
									className="gray small sans"
									onClick={async (event) => {
										try {
											const { data } = await axios({
												url: `${config.apiURL}/api/v1/p/posts/up`,
												method: 'POST',
												data: {
													version: config.version,
													client: config.client,
													accessToken: auth.accessToken,
													...post,
												},
											})

											loadData()
										} catch (error) {
											if (config.debug) console.log(error)
										}
									}}
								>
									&uarr;
								</button>
								<button
									className="gray small sans"
									onClick={async (event) => {
										try {
											const { data } = await axios({
												url: `${config.apiURL}/api/v1/p/posts/down`,
												method: 'POST',
												data: {
													version: config.version,
													client: config.client,
													accessToken: auth.accessToken,
													...post,
												},
											})

											loadData()
										} catch (error) {
											if (config.debug) console.log(error)
										}
									}}
								>
									&darr;
								</button>
								{!post.readonly && (
									<a href={`/blocks/${post.id}`}>
										<button className="gray small sans">&rarr;</button>
									</a>
								)}
							</div>
							<div className="checks">
								<label htmlFor={`post_enabled${i}`}>visibile</label>
								<input
									type="checkbox"
									name={`post_enabled${i}`}
									id={`post_enabled${i}`}
									checked={post.enabled}
									onChange={async (event) => {
										updatePost(
											{
												...post,
												enabled: event.target.checked,
											},
											i
										)
									}}
								/>
								<br />
								<label htmlFor={`post_open${i}`}>aperto</label>
								<input
									type="checkbox"
									name={`post_open${i}`}
									id={`post_open${i}`}
									checked={post.open}
									onChange={async (event) => {
										updatePost(
											{
												...post,
												open: event.target.checked,
											},
											i
										)
									}}
								/>
								<br />
								<label htmlFor={`post_link${i}`}>link</label>
								<input
									type="checkbox"
									name={`post_link${i}`}
									id={`post_link${i}`}
									checked={post.link}
									onChange={async (event) => {
										updatePost(
											{
												...post,
												link: event.target.checked,
											},
											i
										)
									}}
								/>
							</div>
						</div>
						{post.link && (
							<div className="row link">
								<a target="_blank" href={post.link_url} style={{ backgroundColor: post.link_color_bg, color: post.link_color_fg }}>
									{post.link_name}
								</a>
								<a target="_blank" href={post.link_url_en} style={{ backgroundColor: post.link_color_bg, color: post.link_color_fg }}>
									{post.link_name_en}
								</a>
								<div className="">
									<span>IT</span>
									<input
										type="text"
										placeholder="Titlo link"
										value={post.link_name}
										onChange={async (event) => {
											updatePost(
												{
													...post,
													link_name: event.target.value,
												},
												i
											)
										}}
									/>
									<input
										type="text"
										placeholder="URL link"
										value={post.link_url}
										onChange={async (event) => {
											updatePost(
												{
													...post,
													link_url: event.target.value,
												},
												i
											)
										}}
									/>
								</div>
								<div className="">
									<span>EN</span>
									<input
										type="text"
										placeholder="Titlo link (en)"
										value={post.link_name_en}
										onChange={async (event) => {
											updatePost(
												{
													...post,
													link_name_en: event.target.value,
												},
												i
											)
										}}
									/>
									<input
										type="text"
										placeholder="URL link (en)"
										value={post.link_url_en}
										onChange={async (event) => {
											updatePost(
												{
													...post,
													link_url_en: event.target.value,
												},
												i
											)
										}}
									/>
								</div>
								<div className="colors">
									<ChromePicker
										color={post.link_color_bg}
										onChange={async (c) => {
											updatePost(
												{
													...post,
													link_color_bg: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`,
												},
												i
											)
										}}
									/>
									<ChromePicker
										color={post.link_color_fg}
										onChange={async (c) => {
											updatePost(
												{
													...post,
													link_color_fg: `rgba(${c.rgb.r},${c.rgb.g},${c.rgb.b},${c.rgb.a})`,
												},
												i
											)
										}}
									/>
								</div>
							</div>
						)}
					</div>
				))}

				<div className="post">
					<div className="ord">+</div>
					<div className="name">
						<input
							type="text"
							value={name}
							onChange={(event) => {
								setName(event.target.value)
							}}
						/>
					</div>
					<div className="buttons">
						<button
							className="gray small sans"
							onClick={async (event) => {
								try {
									const { data } = await axios({
										url: `${config.apiURL}/api/v1/p/posts/upsert`,
										method: 'POST',
										data: {
											version: config.version,
											client: config.client,
											accessToken: auth.accessToken,
											name,
											page_id: page.id,
											ord: posts.length + 1,
										},
									})

									loadData()
									setName('')
								} catch (error) {
									if (config.debug) console.log(error)
								}
							}}
						>
							Aggiungi post
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Posts
