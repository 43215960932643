export default {
	name: 'lefiole',
	apiURL: 'https://back.lefiole.vscloud.it',
	debug: false,
	version: 0.01,

	google: {
		analytics_monitoring_id: '',
		tag_manager_id: '',
	},
	tinyMCE: {
		apiKey: 'kb8cn7f8h0ra51uz94mzp413bn5vle0rv3o7t6sensr5h9hk',
	},
}
